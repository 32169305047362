<template>
    <div class="title-template-table  table-responsive c-config-content">
        <h4 class="c-config-content__title pt-1">Danh sách Email cá nhân</h4>
        <p>Sử dụng để gửi Email đi, có thể sử dụng cấu hình Thủ công hoặc Gmail</p>
        <g-loading v-if="firstLoading" />
        <div v-else class="title-template-table  table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Loại</th>
                        <th class='text-center'>Trạng thái</th>
                        <th class='text-center'>Mặc định</th>
                        <th class="text-right">
                            <span @click="create" v-if="permissions('user-email_create')" class="btn-add"><i class="icon16-plus-circle-gadient"></i></span>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="listLoading">
                    <tr><td colspan="5" class="text-center text-primary"><b-spinner></b-spinner></td></tr>
                </tbody>
                <tbody v-else-if="!listLoading && !itemCount" class="text-center">
                    <tr>
                        <th colspan="5">
                            Không có Email cá nhân nào được cài đặt
                        </th>
                    </tr>
                </tbody>
                <tbody v-if="!listLoading && itemCount">
                    <tr v-for="item in items" :key="item._id">
                        <td>{{ item.email }}</td>
                        <td>
                            <span v-if='typeOptions.find(o => o.value === item.type)'>{{ typeOptions.find(o => o.value === item.type).text}}</span>
                        </td>
                        <td class='text-center'>
                            <i v-if="item.blocked > 0" class="small text-danger" >Tạm khoá</i>
                            <i v-else class="small text-success" >Hoạt động</i>
                        </td>
                        <td class='text-center text-gray'>
                            <span v-if="item.default > 0" >Mặc định</span>
                        </td>
                        <td class="text-right">
                <g-button v-if="item.type !== 'gmail' && permissions('user-email_update')" :loading="updateLoading" size="xs" variant="outline-info" class="ml-1" icon="pencil" @click='update(item.id)'/>
                <g-button v-if="permissions('user-email_del')" :loading="delLoading" size="xs" variant="outline-danger" class="ml-1" icon="trash-fill" @click='del(item.id)' />
                </td>
                </tr>
                </tbody>
            </table>
        </div>

        <b-modal v-model="showForm" :title="formTitle" @ok="save" no-close-on-backdrop no-enforce-focus>
            <form-select :disabled="formModel.id" label="Loại cài đặt" :model="formModel" attribute="type" :errors="formErrors" :options="typeOptions"></form-select>
            <form-input v-if="formModel.type !== 'gmail'" :disabled="formModel.id" label="Email" :model="formModel" :errors="formErrors" attribute="email"></form-input>

            <form-input v-if="formModel.type === 'manual'" label="Mật khẩu" :model="formModel" :errors="formErrors" attribute="password"></form-input>
            <form-input v-if="formModel.type === 'manual' || formModel.type === 'amz-ses'" label="Máy chủ" :model="formModel" :errors="formErrors" attribute="host"></form-input>
            <form-input v-if="formModel.type === 'manual' || formModel.type === 'amz-ses'" label="Cổng kết nối" :model="formModel" :errors="formErrors" attribute="port"></form-input>

            <form-input v-if="formModel.type === 'amz-ses'" label="Tài khoản SMTP" :model="formModel" :errors="formErrors" attribute="ses_smtp_username"></form-input>
            <form-input v-if="formModel.type === 'amz-ses'" label="Mật khẩu SMTP" :model="formModel" :errors="formErrors" attribute="ses_smtp_password"></form-input>

            <form-checkbox v-if='formModel.id' chk-label="Tạm khoá tài khoản" :model="formModel" :errors="formErrors"  attribute="blocked" />
            <form-checkbox  chk-label="Đặt là mặc định" :model="formModel" :errors="formErrors"  attribute="default" />

            <div v-if="formModel.type === 'amz-ses'" class="alert alert-warning">
                <div>- <b>Email</b> là email được xác thức trong phần cài đặt của Amazon SES</div>
                <div>- Hướng dẫn lấy tại Access Key và Secret Key https://www.youtube.com/watch?v=7z9BCeVeokQ</div>
            </div>

            <template v-slot:modal-footer>
                <button v-if="formModel.type === 'gmail'" class="btn btn-primary" @click="submit">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <i v-else class="fab fa-google-plus-g"></i>
                    Cấp quyền truy cập Gmail
                </button>
                <button :disabled="saveLoading" v-else class="btn btn-primary" @click="save">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <i v-else class="fa fa-mail-bulk"></i>
                    Lưu lại cấu hình
                </button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from '@/mixins/List';
    import curd from '@/mixins/CRUD';
    import Vue from 'vue';
    import GAuth from 'vue-google-oauth2';
    import role from '@/mixins/Role';

    Vue.use(GAuth, {
        clientId: process.env.VUE_APP_GOOGLE_ID,
        scope: 'profile email',
        prompt: 'select_account consent',
        access_type: "offline"
    });

    export default {
        name: "UserEmail",
        components: {},
        mixins: [list, curd,role],
        data: function () {
            return {
                service: '/user-email',
                typeOptions: [
                    {value: null, text: "Chọn cấu hình"},
//                    {value: 'gmail', text: "Gmail"},
                    {value: 'amz-ses', text: "Amazon SES"},
                    {value: 'manual', text: "Thủ công"}
                ],
                defaultFormModel: {
                    type: null,
                    google_oauth_token: null
                },
            }
        },
        methods: {
            submit: function () {
                let vm = this;
                this.formModel.google_oauth_token = null;
                this.$gAuth.getAuthCode(function (code) {
                    vm.formModel.google_oauth_token = code;
                    vm.save().then(() => {
                        vm.showForm = false;
                    });
                }, function (error) {
                    if (error) {
                        vm.$swal({
                            text: 'Chưa hoàn tất việc tích hợp email',
                            icon: 'error'
                        });
                    }
                });
            },
        }
    }
</script>