<template>
    <div class="card title">
        <div class="card-body title-body p-3">
            <h3 class="tile-title is-line mb-2">
                Cài đặt Email
            </h3>
            <user-email />
        </div>
    </div>
</template>

<script>
    import UserEmail from "@/views/setting/communication/email/UserEmail";

    export default {
        name: "Email",
        components: {UserEmail}
    }
</script>